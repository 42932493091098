<template>
  <div class="v" :class="targetDependFormCode ? '' : 'app-container'">
    <div class="head-container" v-if="!targetDependFormCode">
      <quick-select v-model="query.shopId" url="api/shop" placeholder="门店" clearable filterable @change="toQuery" value-field="id" display-field="shopName" class="filter-item" style="width: 200px;" />
      <!-- <quick-select class="filter-item" v-model="query.warehouseId" clearable displayField="name" url="api/warehouse" placeholder="发货库房" filterable @change="toQuery" style="width:180px" /> -->
      <el-select v-model="query.statusList" class="filter-item" clearable placeholder="状态" style="width: 140px" multiple collapse-tags @change="toQuery">
        <template v-for="(o, i) in status">
          <el-option :key="o.label" :label="o.label" :value="i" />
        </template>
      </el-select>
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <el-select v-model="query.isPrinted" filterable clearable placeholder="打印状态" class="filter-item" style="width: 120px;" @change="toQuery">
        <el-option label="已打印" :value="true" />
        <el-option label="未打印" :value="false" />
      </el-select>
      <el-input v-model="query.code" class="filter-item" :maxlength="20" :placeholder="targetDependFormCode ? '发货单号/合同号' : '发货单号/零售单号/合同号'" @keypress.enter.native="toQuery" style="width: 160px;" />
      <el-input v-model="query.customerName" class="filter-item" :maxlength="20" placeholder="收货人" @keypress.enter.native="toQuery" style="width: 120px;" />
      <el-input v-model="query.info" class="filter-item" placeholder="发货备注" @keypress.enter.native="toQuery" style="width: 120px;" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload" v-permission="['RETAIL_DELIVERY_ALL','RETAILMANAGE_EXPORT']">导出</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="add" v-if="checkPermission(['RETAIL_DELIVERY_ALL', 'RETAIL_DELIVERY_EDIT'])">新建</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
      <!-- <el-button class="filter-item" type="danger" icon="el-icon-upload2" @click="audit">审核</el-button> -->
    </div>
    <el-table border v-loading="loading" row-key="id" :data="data" :row-class-name="e => {return e.row.warehouseId ? '' : 'el-table-warning-row';}" height="200px">      
      <el-table-column prop="formCode" label="发货单号" width="120" />
      <el-table-column label="单据日期" width="85" :formatter="r => { return new Date(r.formTime).format('yyyy/MM/dd')}" />
      <el-table-column label="零售单号" prop="dependFormCode" width="120" v-if="!targetDependFormCode" />
      <el-table-column label="门店" prop="shopName" width="120" show-overflow-tooltip />
      <el-table-column label="合同号" prop="contractNo" width="100" show-overflow-tooltip />
      <el-table-column label="发货备注" prop="info" min-width="100" show-overflow-tooltip />
      <el-table-column prop="customerName" label="收货人" width="100" show-overflow-tooltip />
      <el-table-column prop="customerPhone" label="联系电话" width="100" />
      <el-table-column label="收货地址" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.districtName}}{{scope.row.customerAddress}}</template>
      </el-table-column>
      <!-- <el-table-column label="发货仓库" width="130" prop="warehouseName" show-overflow-tooltip /> -->
      <el-table-column prop="totalMoney" label="金额" width="120" align="right" :formatter="$price" />
      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="打印状态" width="80" v-if="!targetDependFormCode">
        <template slot-scope="scope">{{scope.row.isPrinted?'已打印':'未打印'}}</template>
      </el-table-column>
      <el-table-column label="修改时间" width="135" :formatter="r => { return new Date(r.editAt).format('yyyy/MM/dd hh:mm:ss'); }" />
      <el-table-column key="p_opera" width="50" align="center" v-if="targetDependFormCode">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="view(scope.row)" v-if="scope.row.status!=='input'">查看</el-button>
        </div>
      </el-table-column>
      <el-table-column key="e_opera" width="160" fixed="right" align="center" v-else>
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="view(scope.row)" v-if="scope.row.status!=='input'">查看</el-button>
          <el-button size="mini" type="text" @click="print(scope.row)" v-if="scope.row.status!=='input'" v-permission="['RETAIL_DELIVERY_ALL', 'RETAIL_DELIVERY_PRINT']">打印</el-button>
          <el-button size="mini" type="text" @click="view(scope.row)" v-permission="['RETAIL_DELIVERY_ALL', 'RETAIL_DELIVERY_EDIT']" v-if="scope.row.status==='input' || scope.row.status==='reject'">编辑</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180" v-permission="['RETAIL_DELIVERY_ALL', 'RETAIL_DELIVERY_DEL']">
            <p>确定删除吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="del(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini" v-if="scope.row.status==='input'">删除</el-button>
          </el-popover>
          <el-button size="mini" type="text" @click="audit(scope.row)" v-permission="['RETAIL_DELIVERY_ALL', 'RETAIL_DELIVERY_AUDIT']" v-if="scope.row.status==='submit'">审批</el-button>
          <el-button size="mini" type="text" @click="close(scope.row)" v-permission="['RETAIL_DELIVERY_ALL', 'RETAIL_DELIVERY_CLOSE']" v-if="scope.row.status==='confirm'">关闭</el-button>
          <el-button size="mini" type="text" @click="rollback(scope.row)" v-permission="['RETAIL_DELIVERY_ALL', 'RETAIL_DELIVERY_AUDIT']" v-if="scope.row.status==='confirm'">反审核</el-button>
        </div>
      </el-table-column>
    </el-table>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <eForm ref="form" :target-depend-form-code="targetDependFormCode" />
  </div>
</template>

<script>
import eForm from "./form";
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import {
  del,
  closeOrder,
  rollbackOrder,
  printOrder,
} from "@/api/deliveryNotice";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  components: { eForm },
  mixins: [initData],
  props: {
    targetDependFormCode: String,
  },
  data() {
    return {
      downloadLoading: false,
      closing: false,
      delLoading: false,
      selection: null,
      status: {
        input: {
          type: "info",
          label: "编辑中",
        },
        submit: {
          type: "warning",
          label: "待审批",
        },
        confirm: {
          type: "success",
          label: "已确认",
        },
        reject: {
          type: "danger",
          label: "已拒绝",
        },
        close: {
          type: "info",
          label: "已关闭",
        },
        outbound: {
          type: "success",
          label: "已出库",
        },
        partOut: {
          type: "success",
          label: "部分已出库",
        },
      },
      order: {
        show: false,
        loading: false,
        current: null,
      },
      query: {
        status: null,
        dateRange: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/shop/deliveryNoticeVew";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.beginTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      delete query.dateRange;
      if (this.targetDependFormCode) {
        query.dependFormCode = this.targetDependFormCode;
      }
      this.params = Object.assign(
        { status: query.status, sort: "editAt,desc" },
        query
      );
      return true;
    },
    handleSelectionChange(sels) {
      this.selection = sels;
    },
    checkReportable(data) {
      return data.status === "input";
    },
    view(data, lock = false) {
      this.$refs.form && this.$refs.form.resetForm(data, lock);
    },
    add() {
      this.$refs.form && this.$refs.form.resetForm({});
    },
    del(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    print(data) {
      this.$print("retail-consignment", data.id);
      //refresh print
      if (!data.isPrinted) {
        printOrder(data.id).then((_) => {
          data.isPrinted = true;
        });
      }
    },
    audit(data) {
      //console.log(this.selection);
      this.$refs.form && this.$refs.form.resetForm(data, true);
    },
    close(data) {
      this.$confirm(
        `您确定要关闭发货通知单【${data.formCode}】吗？`,
        "操作确认",
        {
          type: "warning",
        }
      ).then((_) => {
        this.closing = true;
        closeOrder(data.id)
          .then((_) => {
            this.init();
            this.$notify({
              title: "关闭成功",
              type: "success",
              duration: 2500,
            });
          })
          .finally((_) => {
            this.closing = false;
          });
      });
    },
    rollback(data) {
      this.$confirm(
        `您确定要反审核发货通知单【${data.formCode}】吗？`,
        "操作确认",
        {
          type: "warning",
        }
      ).then((_) => {
        this.closing = true;
        rollbackOrder(data.id)
          .then((_) => {
            this.init();
            this.$notify({
              title: "反审成功",
              type: "success",
              duration: 2500,
            });
          })
          .finally((_) => {
            this.closing = false;
          });
      });
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/shop/deliveryNotice/download", this.params)
        .then((result) => {
          downloadFile(result, "发货通知明细数据", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>